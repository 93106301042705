:root {
  --primary-color: #000;
  --font-stack: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  color: var(--primary-color);
  font: 14px var(--font-stack);
  background: yellow;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
}

.Name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-weight: 500;
  text-transform: uppercase;
}

.Name .title {
  font-size: 1.15rem;
  letter-spacing: 5px;
  margin: 3px 0;
}

.Contact {
  display: flex;
  color: #868e96;
  font-size: 0.6rem;
  letter-spacing: 3px;
}

.Projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Container {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
}

.Projects > div:first-child > .Project {
  padding-top: 0;
}

.Project {
  display: flex;
  padding: 100px 0;
  max-width: 1500px;
}

.Project .title {
  font-weight: 500;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.Project .details {
  margin: 10px 0;
  line-height: 1.75;
  color: #868e96;
}

.Project .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  margin: 0 35px;
}

.Project .image {
  width: 55%;
  margin: 0 35px;
}

.Project .presentation {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 65%;
}

svg {
  width: 100%;
  height: 100%;
}

.Project .image img {
  width: 100%;
  box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 900px) {
  .Project {
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
  }
  .Project .info,
  .Project .image,
  .Project .presentation {
    margin: 15px 0;
    width: 90%;
  }
  .Name {
    height: 250px;
  }
}
